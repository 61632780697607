import React from "react";
import {graphql} from "gatsby";
import Link from "components/link";
import DefaultLayout from "components/layout/default";
import "styles/markdown.css";
import layoutStyle from "styles/layout.module.css";

const NAV_LINK_DEFAULT_PROPS = {
  className: "text-sm text-black opacity-50 font-semibold mr-6 uppercase pb-2 inline-block",
  activeClassName: "text-orange opacity-100 border-b-2 border-orange"
};

function LegalTemplate(props) {
  let pageContent = props.data.pageContent.childMarkdownRemark;

  return <DefaultLayout location={props.location}
    headerFill="always"
    title={pageContent.frontmatter.title}
    description={pageContent.frontmatter.description}>
    <div className={layoutStyle.headerPlaceholder}/>
    <div className="container mx-auto mb-8 md:px-8 z-10 relative">
      <nav className="bg-white px-6 md:px-8 pt-6 hidden md:block">
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/">Website T&Cs</Link>
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/client/">Terms Of Business</Link>
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/privacy/">Privacy Notice</Link>
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/cookie/">Cookie Policy</Link>
      </nav>
      <nav className="bg-white px-6 md:px-8 pt-6 block md:hidden">
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/">Website T&Cs</Link>
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/client/">Terms Of Business</Link>
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/privacy/">Privacy Notice</Link>
        <Link {...NAV_LINK_DEFAULT_PROPS} to="/legal/cookie/">Cookie Policy</Link>
      </nav>
      <article className="markdown-body bg-white px-6 md:px-16 py-6 md:py-16"
        dangerouslySetInnerHTML={{__html: pageContent.html}}/>
    </div>
  </DefaultLayout>;
}

export default React.memo(LegalTemplate);

export const QUERY = graphql`
  query LegalTemplateQuery($fileId: String!) {
    pageContent: file(id: {eq: $fileId}) {
      childMarkdownRemark {
        id
        html
        frontmatter {
          title
          description
        }
      }
    }
  }
`;
